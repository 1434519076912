import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const blogApi = createApi({
    reducerPath: 'blogApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://blog.travis.tg/api/',
        tagTypes: ['Post'],
    }),
    endpoints: (builder) => ({
        getPosts: builder.query({
            query: () => '/blog/',
            providesTags: ['Post']
    }),
        getComments: builder.query({
            query: () => '/comment/?ordering=-date',
 
    })
    
})
})

export const {
    useGetPostsQuery,
    useGetCommentsQuery,
} = blogApi;

